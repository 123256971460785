import {getImageFromUrl,getImageFromUrl2} from '@/plugins/connect';

import { barcodeOutline, personOutline, printOutline, speedometerOutline, cartOutline, fileTrayStackedOutline, locationOutline, list, ellipsisVertical, shieldCheckmarkOutline, giftOutline } from 'ionicons/icons';
//const server = (process.env.NODE_ENV == "development") ? 'http://' + document.location.hostname + ':3000' : 'https://' + document.location.hostname
let server = (process.env.NODE_ENV == "development") ? 'http://' + document.location.hostname + ':3000/api' :  document.location.origin + '/api' //'https://' + document.location.origin + '/api'
//if (document.location.origin.includes('todoo.nl')) server = 'https://server.todoo.nl/api'
//if (!['localhost','release.todoo.nl','portal.todoo.nl', 'release.wylance.net','localhost:8080','release.wylance.net', 'release.todoo.nl','webshop.zorgservicexl.nl','zxl.todoo.nl'].includes(document.location.hostname)) server = 'https://server.todoo.nl/api'

//if (process.env.API_SERVER) server  = process.env.API_SERVER

const config: any = {
  store_name: 'zxl-store',
  version: '0.9.9.9.7',
  development: (process.env.NODE_ENV == "development") ,
  server,
  image_url: 'https://zorgservicexl.odoo.com/web/image/',
  donload_link : server + '/order/download/${file.id}?x-api-key:ODOO-08yHhdd0KFWsQyZtUKlWwsyiK64jEo7ygasdASd9',
  store: {
    smartman: { logo: "/assest/icon/icon.png" }
  },
  public: {
    api: server + '/',
    key: 'root'
  },
  connections: [
    
    {index:0,
      active: true,
      source: "", //"odoo/"
      name: "ZXL Acceptatie omgeving",
      description: 'Klik hier als u de omgeving voor ZXL wilt testen',
      key: 'ODOO-08yHhdd0KFWsQyZtUKlWwsyiK64jEo7ygasdASd9',
      logo2: "./img/ZorgserviceXL_logo.jpg",
      port: 80,
      bg: '/img/bg_login.png',
      class: 'odoo',
      logo: "/img/zxllogo.png",
      icon: "/img/odoo_logo.png",
      redir: '/shop/start',
      version: 'v2',
      url: ['localhost:8080','localhost:3000', 'release.todoo.nl','portal.todoo.nl', 'release.wylance.net'] 
    },
  
    {index:1,
      source: "", //"odoo/"
      active: true,
      none:true,
      name: "ZorgserviceXL live",
      description: 'Productieomgeving ZXL',
      key: 'ODOO-08yHhdd0KFWsQyZtUKlWwsyiK64jEo7ygasdASd9',
      logo2: "./img/ZorgserviceXL_logo.jpg",
      host: 'zorgservicexl.odoo.com', port: 80,
      database: 'cravit-zorgservicexl-production-6119727',
      code: "a1adc834361ce0bed08309f2be6fc1ed2d549730",
      bg: '/img/bg_login.png',
      class: 'odoo',
      logo: "/img/zxllogo.png",
      icon: "/img/odoo_logo.png",
      redir: '/shop/start',
      version: 'v2',
      url: ['localhost:8080','release.wylance.net', 'release.todoo.nl','webshop.zorgservicexl.nl','zxl.todoo.nl'] //,'webshop.zorgservicexl.nl'
    },/*
    {
      source: "", //"odoo/"
      name: "ZorgserviceXL Mockup",
      key: 'ODOO-08yHhdd0KFWsQyZtUKlWwsyiK64jEo7ygasdASd9',
      logo2: "./img/ZorgserviceXL_logo.jpg",
      host: 'zorgservicexl-16-0-mockup-frontend-9594547.dev.odoo.com', port: 80,
      database: 'zorgservicexl-16-0-mockup-frontend-9594547',
      bg: '/img/bg_login.png',
      class: 'odoo',
      logo: "/img/zxllogo.png",
      icon: "/img/odoo_logo.png",
      redir: '/shop/start',
      version: 'v1'
    },
    
    {
      source: "", //"odoo/"
      name: "ZorgserviceXL Webshop",
      key: 'ODOO-08yHhdd0KFWsQyZtUKlWwsyiK64jEo7ygasdASd9',
      logo2: "./img/ZorgserviceXL_logo.jpg",
      host: 'servicecenter2.odoo.com', port: 80,
      database: 'servicecenter2',
      bg: '/img/bg_login.png',
      class: 'odoo',
      logo: "/img/zxllogo.png",
      icon: "/img/odoo_logo.png",
      redir: '/shop/start',
      version: 'v1'
    }*/
  
  ],
  redirs: [
    {role : 'Kastenscanner', path: '/shop/start'}
  ],
  admin: {
    id: 1,
    name: 'Zorgservice XL' 
  },
  portal: {
    id: 36 ,
    role: 'Dienst',
    name: 'Portal user',
    display: 'Besteller',
    type: 'Gereedmelding',
    title: 'Gereedmelding',
  },
  home: {
    "admin": '/',
    "user": '/',
    "scanner": '/scanner/start',
  },
  main_menu: [
    {name: "Start", link : "/shop/start"},
    {name: "Bestellen", link : "/shop/list"},
    {name: "Bestellingen", link : "/admin/orderlines"},
    {name: "Nieuws", link : "/site/news"},
    {name: "Faq", link : "/site/faq"}
  ],
  site: [
    { name: "Nieuws", image: '/img/news.jpg', description: "Bekijk de laatste nieuwsitems", link: '/site/news', list: 'posts' },
    { name: "Veel gestelde vragen", image: '/img/faq.jpg', description: "Een overzicht van veel gestelde vragen en antwoorden", link: '/site/faq', list: 'articles' },
    { name: "Bestellingen", image: '/img/orders.jpg', description: "Openstaande bestellingen", link: '/admin/orderlines', list: 'orders' },
    { name: "Goedkeuringen", image: '/img/procuration.jpeg', description: "Openstaande goedkeuringen en gereedmeldingen", link: '/admin/orderlines', list: 'todo', slice: 10 },
    { name: "Helpdesk", image: '/img/procuration.jpeg', description: "Openstaande tickets", link: '/admin/tickets', list: 'tickets', slice: 10 },
  ],
theme : 
    { "default": "rdg",
        "Zorgservice XL": "zxl",
      "Franciscus Gasthuis & Vlietland": "franciscus",
        "HagaZiekenhuis": "haga",
        "IJsselland Ziekenhuis": "ijsselland",
        "LangeLand Ziekenhuis": "haga",
        "HagaZiekenhuis Zoetermeer": "haga",
        "Reinier Haga Orthopedisch Centrum": "rhoc",
        "Reinier de Graaf": "rdg",
    }
,

  catalog: [
    { name: "Catalogus Bestelling", title:"Zoeken in de catalogus", type: 1, color: "blue", category: "Voorraadproducten,Verbuiksartikelen", feature:true, value: 'catalog', catalog: 'Catalogus', checkout:true, description:"Bestel voorraad- en koopproducten uit uw ziekenhuiscatalogus."},
    { name: "OCI", dummy: 'oci', title:"Externe catalogus", type: 1, color: "green", category: "OCI", banner: 'Extern', detail: true, checkout:true , value: 'oci', catalog: 'Catalogus', description: "Rechstreeks bestellen bij een van onze leveranciers"},
    { name: "Vrije tekst aanvraag", expert: true, allowcatalog: true, dummy: "vta", menu: true, type: 9, group: true, color: "olive", banner: 'VTA', category: "VTA", procuration: true, detail: true, suppliers:true, multiple: true, checkout:true , assort:true, singelform: true,  catalog: 'Catalogus' , value: 'vta', stream: 'VTA', description: "Voor het bestellen van een product dat nog niet in de catalogus aanwezig is.", img: '/img/vta.jpeg'},
    { name: "Dienst",vta:true, service: true, dummy: "service", approve: true, color: "green", type: 2, menu: true, group: true, unit: 'Tijd', banner: 'Dienst', category: "Diensten", procuration: true, value: 'service' ,multiple: true, catalog: 'Dienst', contract: false, description: "Voor dienstaanvragen, zoals: reparatie, huur, service etc.", img: '/img/vta.jpeg'},
    { name: "Bruikleen",vta:true, service: true, dummy: "bruikleen", min_amount: 0.01, approve: true,value: 'bruikleen', type: 3, group: true, color: "purple", banner: 'bruikleen', category: "Diensten / Bruikleen", catalog: 'Bruikleen', procuration: true , contract: false, multiple: true,  detail: true, menu: true, description: "Voor het aanvragen van bruikleensets en artikelen. Minimaal 0.01 euro", img: '/img/vta.jpeg'},
    { name: "Investering",vta:true, service: true, dummy: "invest", approve: true, suppliers:true, value: 'investering', type: 4, group: true, color: "dark", banner: 'Investering', category: "Diensten / Investering", catalog: 'Investering', procuration: true, detail: true , productgroup: 'Interne orders ziekenhuizen' ,multiple: true, invest:true, menu: true, description:"Hier kunt u aankopen die niet op explotatie rekeningen worden geboekt aanvragen. U dient altijd een <span>goedgekeurde investeringsnummer en offerte toe te voegen.", img: '/img/vta.jpeg'},
    { name: "Consignatie",vta:true, value: 'consignatie', type: 6, color: "stale", banner: 'Consignatie', category: "Verbuiksartikelen / Consignatie", catalog: 'Consignatie', procuration: true, multiple: true, detail: true, options: [{name: "lot", label: "Lotnummer" }] , menu: true, description:"Hier kunt u het aanleggen van een consignatievoorraad aanvragen. Graag hierbij vermelden welk lotnummer is verbruikt, zodat de leverancier deze in rekening kan brengen.", img: '/img/vta.jpeg'},
    { name: "Zichtzending / Proefplaatsing",vta:true, type: 7,  value: 'Zichtzending', banner: 'proef', color: "yellow", category: "Verbuiksartikelen / Proef", catalog: 'Zichtzending/Proefplaatsing', procuration: true, detail: true, multiple: true , menu: true, description: "U kunt hier een zichtzending of proefplaatsing aanvragen.", img: '/img/vta.jpeg' },
    { name: "Jaarorder",vta:true, suppliers:true, value: 'jaarorder', type: 5, color: "fire", banner: 'jaarorder', allowPrice: true, allowcatalog: true, category: "Diensten / Jaarorder", catalog: 'Jaarorder', multiple: true, contract: false, procuration: true, detail: true, menu: true, description: "Hier kun je een bestelling plaatsen wanneer voor de levering van de artikelen een leverschema bestaat waar gedurende een periode of kalenderjaar op geleverd wordt. De jaarorder. Jaarorders worden direct op locatie van het ziekenhuis afgeleverd. ", img: '/img/vta.jpeg'},
    { name: "Kastenscan", title:"Kastenscan", type: 8, color: "blue", category: "Kastenscan", value: 'Kastenscan', catalog: 'Kastenscan', checkout:true, description:"Scannen en beheren van kasten", home: "/shop/start"},
  ], //Aanvullende info voor de leverancier BESTANDEN??
  producttype: {
    'consu': 'Koopproduct',
    'product': "Voorraadproduct",
    'service': "Dienst"
  },
  ordertype: [

  ],
  vat: [
    {value: 21, text: 'BTW hoog 21%', id: 4},
    {value: 9, text: 'BTW laag 9%', id: 3},
    {value: 0, text: 'Geen BTW', id:1}
  ],
  routes: {
    oci : [4, 48],
    vta : [4, 48],
    dropship: [5, 21] 
  },
  activity: {
    model: 737,
    invoice: 524,
    type:[
      {
          "id": 1,
          "sequence": 3,
          "name": "E-mail",
          "summary": false,
          "delay_label": "0 dagen",
          "delay_from": "previous_activity",
          "res_model": false,
          "icon": "fa-envelope"
      },
      {
          "id": 2,
          "sequence": 4,
          "name": "Bel",
          "summary": false,
          "delay_label": "2 dagen",
          "delay_from": "previous_activity",
          "res_model": false,
          "icon": "fa-phone"
      },
      {
          "id": 3,
          "sequence": 5,
          "name": "Afspraak",
          "summary": false,
          "delay_label": "0 dagen",
          "delay_from": "previous_activity",
          "res_model": false,
          "icon": "fa-users"
      },
      {
          "id": 18,
          "sequence": 6,
          "name": "Upsell order",
          "summary": false,
          "delay_label": "0 dagen",
          "delay_from": "previous_activity",
          "res_model": "sale.order",
          "icon": "fa-line-chart"
      },
      {
          "id": 4,
          "sequence": 7,
          "name": "Te doen",
          "summary": false,
          "delay_label": "5 dagen",
          "delay_from": "previous_activity",
          "res_model": false,
          "icon": "fa-tasks"
      },
      {
          "id": 5,
          "sequence": 8,
          "name": "Document uploaden",
          "summary": false,
          "delay_label": "5 dagen",
          "delay_from": "previous_activity",
          "res_model": false,
          "icon": "fa-upload"
      },
      {
          "id": 25,
          "sequence": 9,
          "name": "Handtekening aanvragen",
          "summary": false,
          "delay_label": "2 dagen",
          "delay_from": "previous_activity",
          "res_model": false,
          "icon": "fa-pencil-square-o"
      },
      {
          "id": 30,
          "sequence": 10,
          approve: true,
          display: true,
          "name": "Beoordeling Spoedorder",
          "summary": false,
          "delay_label": "0 dagen",
          "delay_from": "previous_activity",
          "res_model": false,
          "icon": "fa-check"
      },
      {
          "id": 29,
          "sequence": 11,
          approve: true,
          display: true,
          "name": "Beoordeling Bestelling",
          "summary": false,
          "delay_label": "0 dagen",
          "delay_from": "previous_activity",
          "res_model": false,
          "icon": "fa-check"
      },
      {
          "id": 28,
          "sequence": 12,
          approve: true,
          display: true,
          "name": "Beoordeling Expertlid",
          "summary": false,
          "delay_label": "0 dagen",
          "delay_from": "previous_activity",
          "res_model": false,
          "icon": "fa-check"
      },
      {
          "id": 11,
          "sequence": 13,
          approve: true,
          display: true,
          "name": "Goedkeuring verlenen",
          "summary": false,
          "delay_label": "0 dagen",
          "delay_from": "previous_activity",
          "res_model": false,
          "icon": "fa-check"
      },
      {
          "id": 31,
          "sequence": 14,
          approve: true,
          display: true,
          "name": "Goedkeuring Facturatie",
          "summary": false,
          "delay_label": "0 dagen",
          "delay_from": "previous_activity",
          "res_model": false,
          "icon": "fa-check"
      },
      {
        "id": 32,
        "sequence": 16,
        approve: true,
        display: true,
        "name": "Gereedmelding",
        "summary": false,
        "delay_label": "0 dagen",
        "delay_from": "previous_activity",
        "res_model": false,
        "icon": "fa-check"
    },
      {
          "id": 27,
          "sequence": 15,
          decline: true,
          display: true,
          "name": "Afgekeurd",
          "summary": false,
          "delay_label": "0 dagen",
          "delay_from": "previous_activity",
          "res_model": false,
          "icon": "fa-check"
      },
      {
          "id": 33,
          "sequence": 16,
          approve: true,
          display: true,
          "name": "Goederen ontvangst",
          "summary": false,
          "delay_label": "0 dagen",
          "delay_from": "previous_activity",
          "res_model": false,
          "icon": "fa-check"
      }
  ], 
    types: [
        {
          id: 1,
          name: "Email"
        }, {
          id: 2,
          name: "Call"
        }, {
          id: 3,
          name: "Meeting"
        }, {
          id: 18,
          name: "Order Upsell"
        }, {
          id: 4,
          name: "To Do"
        }, {
          id: 5,
          name: "Upload Document"
        }, {
          id: 25,
          name: "Request Signature"
        }, {
          id: 11,
          approve: true,
          display: true,
          name: "Goedkeuring",
          text: "Wachten op goedkeuring van",
          
        }, {
          id: 27,
          decline: true,
          display: true,
          name: "Afgekeurd",
          text: "Afgekeurd door"
        }
       
      ]
  },
  "_line": { 
    "model" : "orderline",
    "index": "order_id"
    },
  stocktype: {
    "2bin": "2 BIN",
    "top up": "Top Up",
    "1bin": "1 BIN",
  },
  status: {
    "open": "Openstaand",
    "block": "Ter goedkeuring",
    "sale": "In bestelling",
    "overdue": "Backorder",
    "done": "Geleverd"
  },
  cabinetstatus: [
    "Concept",
    "Actief",
    "Geblokkeerd",
    "Archief"
] ,

  sounds: {
    'message': 'assets/sounds/notification.mp3',
    'error': 'assets/sounds/error.mp3',
    'none': 'assets/sounds/none.mp3'
  },
  apps: [
    { icon: barcodeOutline, name: "Portal", sub: 'Scan', version: '0.8', color: "purple", path: '/home/start', description: 'Scannen van de verschillende kasten op afdelingen in de ziekenhuizen', active: true, role: ['scanner', 'admin'],home: '/shop/list' },
    { icon: barcodeOutline, name: "Kastenscanner", sub: 'Scan', version: '0.8', color: "purple", path: '/home/admin', description: 'Scannen van de verschillende kasten op afdelingen in de ziekenhuizen', active: true, role: ['scanner', 'admin'] },
    { icon: fileTrayStackedOutline, name: "Kastenbeheer", sub: 'Scan', version: '0.9', color: "grey", path: '/home/router', description: 'Beheer van producten in de kasten in de ziekenhuizen', active: true, role: ['scanner', 'admin'] },
    { icon: shieldCheckmarkOutline, name: "Goedkeuringen", sub: 'Scan', version: '0.8', color: "green", path: '/procuration/start', description: 'Goedkeuringen van orders en facturen', active: true, role: ['admin'] },
    { icon: speedometerOutline, name: "Dashboard", sub: 'Scan', version: '0.6.0', color: "orange", path: '/home', description: 'Overzichtelijk dashboard', active: true, role: ['admin'] },
    { icon: cartOutline, name: "Bestelsite", sub: 'Scan', version: '0.8.0', color: "yellow", path: '/shop/start', description: 'Losse bestellingen voor gebruikers', active: true, role: ['admin'] },
    { icon: locationOutline, name: "Track and Trace", sub: 'Scan', version: '0.3.0', color: "blue", path: '/strack/start', description: 'Goedkeuringen van orders en facturen', active: true, role: ['admin'] },
    { icon: printOutline, name: "Barcode, sjablonen en printers", sub: 'Scan', version: '0.1.0', color: "red", path: '/print/start', description: 'Goedkeuringen van orders en facturen', active: true, role: ['admin'] },
  ],
  oci: {
    callback: '/shop/list',
    "https://www.technischeunie.nl" : {
          source: 'techniek',
          productgroup: 1647,
          url: 'https://www.technischeunie.nl/redirect/erpRedirect.jsp',
          thumbnail: async (number:number) => `https://www.technischeunie.nl/images/search_thumbnails/${number}.jpg`,
          image: async (number:number) => `https://www.technischeunie.nl/images/artikel/${number}.gif`,
          params : {
          password: 'vfl7AmG',
          user: 'OCI/1428044',
          disposalcontrib: true,
          SAPOCITYPE: 'FRM',
          HOOK_URL: server + '/oci/techniek/'
        }
    },
    "https://nl.vwr.com" : {
      source: 'lab',
      productgroup: 1418,
      url: 'https://nl.vwr.com/app/oci/OCILogin',
      thumbnails: async (number:number) => await getImageFromUrl(`https://nl.vwr.com/store/catalog/product.jsp?catalog_number=${number}`),
          images: async (number:number) => await getImageFromUrl(`https://nl.vwr.com/store/catalog/product.jsp?catalog_number=${number}`),
      params: {
        username: 'zorgxl@vwr.nl',
        password: 'Zorg@VWR',
        kostensoort: null,
        HOOK_URL: server + '/oci/lab/'
      }
    },
    "https://www.indexbooks.nl" : {
      source: 'lab',
      productgroup: 1387,
      url: 'https://www.indexbooks.nl/oci/ordering.php',
      //thumbnail: async (number:number) => `https://www.technischeunie.nl/images/search_thumbnails/${number}.jpg`,
       //   image: async (number:number) => `https://www.technischeunie.nl/images/artikel/${number}.gif`,
      params: {
        actie: "OCISession",
        HOOK_URL: server + '/oci/lab/',
        USERNAME: 'dG2325008Fq',
        PASSWORD: 'zF5Bm5Sh',
        USER_ID: "dG2325008Fq",
        OkCode: "ok",
        ETARGET: "_top",
        "7ECALLER": "ich",
        OCI_VERSION: "2.0"
       
      }
    },
    "aaaaahttps://staples.nl" : {
      source: 'lab',
      url: 'https://order.staplesadvantage.nl/easyorder.oci/isapv1',
      thumbnail: async (number:number) => `https://www.technischeunie.nl/images/search_thumbnails/${number}.jpg`,
      image: async (number:number) => `https://www.technischeunie.nl/images/artikel/${number}.gif`,
      params: {
        username: '3309114050',
        password: 'OCI9467',
        OCI_VERSION: "2.0",
        HOOK_URL: server + '/oci/lab/'
      }
    },
    "https://www.jostenberg.nl": {
      source: 'lab',
      productgroup: 1314,
      unspsc: true,
      url: 'https://www.jostenberg.nl/sanaCommerceOn',
      thumbnail: async (number:number) => `https://www.technischeunie.nl/images/search_thumbnails/${number}.jpg`,
      image: async (number:number) => `https://www.technischeunie.nl/images/artikel/${number}.gif`,
      params: {
        USERNAME: 'inkoop@zorgservicexl.nl',
        PASSWORD: 'ZsXLink1',
        HOOK_URL: server + '/oci/lab/'
      }
    },
    "https://www.breur.nl": {
      source: 'lab',
      productgroup: 1647,
      unspsc: true,
      url: 'https://www.breur.nl/ocilogin',
      //thumbnail: async (number:number,name:string) => getImageFromUrl2(`https://www.breur.nl/images/thumbs/${number}_${name.replaceAll(' ','-')}`),
      //image: async (number:number,name:string) => getImageFromUrl2(`https://www.breur.nl/images/thumbs/${number}_${name.replaceAll(' ','-')}`),
      params: {
        USERNAME: 'regie-ict@zorgservicexl.nl',
        PASSWORD: 'bestel2021',
        HOOK_URL: server + '/oci/lab/',
        LEADTIME: 'DEFAULT'
      }
    },
    "https://www.lyreco.com":{
      source: 'lab',
      unspsc: true,
      productgroup: 1347,
      url: 'https://www.lyreco.com/DC/FH1/OCI/Login/StdLogin.do',
      //thumbnail: async (number:number,name:string) => getImageFromUrl2(`https://www.breur.nl/images/thumbs/${number}_${name.replaceAll(' ','-')}`),
      //image: async (number:number,name:string) => getImageFromUrl2(`https://www.breur.nl/images/thumbs/${number}_${name.replaceAll(' ','-')}`),
      params: {
        user: 'OCIZORGXL',
        password: 'OCI4ZXL',
        language: 'nl-NL',
        OKCODE: 'ADDI',
        TARGET: '_total',
        FORCETARGET: 'YES',
        CALLER: 'CTLG',
        HOOK_URL: server + '/oci/techniek/'
      }
    },
  },
 
      /*

    <add key="OCI.Breur.Url" value="https://www.breur.nl/ocilogin?USERNAME=regie-ict@zorgservicexl.nl&amp;PASSWORD=bestel2021&amp;hook_url={0}&amp;LEADTIME=DEFAULT" />

    <add key="OCI.JosTenBerg.Url" value="https://www.jostenberg.nl/sanaCommerceOn?USERNAME=inkoop@zorgservicexl.nl&amp;PASSWORD=ZsXLink1&amp;HOOK_URL={0}" />

    <add key="OCI.Lyreco.Url" value="https://www.lyreco.com/DC/FH1/OCI/Login/StdLogin.do" />*/
  roles: [
    "Spoedorder",
    "Vrije tekst aanvraag",
    "Medisch",
    "Geen",
    "Besteller",
    "Manager",
    "Budgethouder",
    "Bruikleen",
    "Dienst",
    "Investering",
    "Consignatie",
    "Zichtzending / Proefplaatsing",
    "Jaarorder",
    "Directeur",
    "Bestuurder",
    "Gatekeeper",
    "Gereedmelding Dienst"

  ],
  procurationroles: [
    "Besteller",
    "Manager",
    "Budgethouder",
    "Directeur",
    "Bestuurder",
    "Medisch",
    "Gatekeeper"
  ],
  gatekeeper: [
    "Medisch",
    "Manager",
    "Budgethouder",
    "Directeur",
    "Bestuurder"
  ],
  approval: [
    "Manager",
    "Budgethouder",
    "Directeur",
    "Bestuurder"
  ],
  actionlist: {
    "Manager": 'Goedkeuring verlenen',
    "Budgethouder": 'Goedkeuring verlenen',
    "Directeur": 'Goedkeuring verlenen',
    "Bestuurder": 'Goedkeuring verlenen',
    "Medisch" : 'Beoordeling Expertlid',
    "Gatekeeper": 'Goedkeuring verlenen',
    "Besteller":'Gereedmelding',
    "Vrije tekst aanvraag": 'Beoordeling Bestelling',
    "Spoedorder": 'Beoordeling Spoedorder',
  },
  assortment: ["Medisch","ICT","Nucleair","Facilitair","Techniek","Medische Techniek"],
  assort : [
      {
        "id": 14,
        "name": "Medisch",
        "ribbon_id": false,
        show: true,
        "color": 8
    },
      {
        "id": 16,
        "name": "ICT",
        "ribbon_id": false,
        show: true,
        "color": 3
    },

    {
        "id": 20,
        "name": "Nucleair",
        "ribbon_id": false,
        "color": 8
    },
    {id: 21, name: "LAB", color: 11,  show: true,},
    {id: 27, name: "Facilitair", color: 11,  show: true,},
    {"id": 22,"name": "Technisch", "color": 6, show: true},
    {
      "id": 28,
      "name": "Medische Techniek"
  }

],
stock_uom: [
  {value: "ST", text:"Stuk"},
  {value: "DS", text:"Doos"},
  {value: "ODS", text:"Omdoos"},
  {value: "PK", text:"Pak"},
  {value: "ZK", text:"Zak"},
  {value: "CS", text:"Case"},
],
  unit: [
    {category_id: 'Eenheid' , type: "vta", name: 'Stuk', db: 'Stuks', unit: 'VERP1' , multiple: 'Stuk', display: (count:number,quantity:number)=> (quantity > 1 ? 'Stuks' : 'Stuk')},
    {category_id: 'Eenheid' , type: "vta", name: 'Doos', db: 'DS', multiple: 'Dozen', count: true, display: (count:number,quantity:number)=>(quantity > 1 ? 'Dozen' : 'Doos') + (count > 1 ? ' van '+ count : '')},
    //{category_id: 'Eenheid' , type: "service", name: 'Uur', db: 'Uur' , unit: 'Uren', multiple: 'Uur', display: (count:number,quantity:number)=> (quantity > 1 ? 'Uren' : 'Uur')},
    //{category_id: 'Eenheid' , type: "service", name: 'Dag', db: 'Dag' , unit: 'Dagen', multiple: 'Dag', display: (count:number,quantity:number)=> (quantity > 1 ? 'Dagen' : 'Dag')},
    //{category_id: 'Eenheid', type: "vta" , name: 'Overig', multiple: 'Overig', display: (count:number,quantity:number)=>'Overig'},
    {category_id: 'Tijd' , type: "service", name: 'Uur', db: 'Uur' , unit: 'Uren', multiple: 'Uur', display: (count:number,quantity:number)=> (quantity > 1 ? 'Uren' : 'Uur')},
    {category_id: 'Tijd' , type: "service", name: 'Dag', db: 'Dag' , unit: 'Dagen', multiple: 'Dag', display: (count:number,quantity:number)=> (quantity > 1 ? 'Dagen' : 'Dag')},
    //{category_id: 'Tijd' , type: "service", name: 'Week' , multiple: 'Week', display: (count:number,quantity:number)=> (quantity > 1 ? 'Weken' : 'Week')},
    //{category_id: 'Tijd' , type: "service", name: 'Maand' , multiple: 'Maand', display: (count:number,quantity:number)=> (quantity > 1 ? 'Maanden' : 'Maand')},
    {category_id: 'Tijd' , type: "service", name: 'Stuk', db: 'Stuks' , unit: 'VERP1', multiple: 'Stuk', display: (count:number,quantity:number)=> (quantity > 1 ? 'Stuks' : 'Stuk')},
    {category_id: 'Tijd' , type: "service", name: 'Doos', db: 'DS', multiple: 'Dozen', count: true, display: (count:number,quantity:number)=>(quantity > 1 ? 'Dozen' : 'Doos') + (count > 1 ? ' van '+ count : '')},
  ],
  units:{
    'Units': 'stuk',
    'Dozens' : 'dozijn'
  },
  unitsss: [
    {
      "id": 6,
      "name": "mm",
      "category_id": [
        4,
        "Lengte / Afstand"
      ],
      "uom_type": "smaller"
    },
    {
      "id": 13,
      "name": "g",
      "category_id": [
        2,
        "Gewicht"
      ],
      "uom_type": "smaller"
    },
    {
      "id": 8,
      "name": "cm",
      "category_id": [
        4,
        "Lengte / Afstand"
      ],
      "uom_type": "smaller"
    },
    {
      "id": 25,
      "name": "in³",
      "category_id": [
        6,
        "Volume"
      ],
      "uom_type": "smaller"
    },
    {
      "id": 17,
      "name": "in",
      "category_id": [
        4,
        "Lengte / Afstand"
      ],
      "uom_type": "smaller"
    },
    {
      "id": 16,
      "name": "oz",
      "category_id": [
        2,
        "Gewicht"
      ],
      "uom_type": "smaller"
    },
    {
      "id": 22,
      "name": "fl oz (USA)",
      "category_id": [
        6,
        "Volume"
      ],
      "uom_type": "smaller"
    },
    {
      "id": 21,
      "name": "ft²",
      "category_id": [
        5,
        "Oppervlakte"
      ],
      "uom_type": "smaller"
    },
    {
      "id": 4,
      "name": "Uren",
      "category_id": [
        3,
        "Werktijd"
      ],
      "uom_type": "smaller"
    },
    {
      "id": 18,
      "name": "ft",
      "category_id": [
        4,
        "Lengte / Afstand"
      ],
      "uom_type": "smaller"
    },
    {
      "id": 15,
      "name": "lb",
      "category_id": [
        2,
        "Gewicht"
      ],
      "uom_type": "smaller"
    },
    {
      "id": 19,
      "name": "yd",
      "category_id": [
        4,
        "Lengte / Afstand"
      ],
      "uom_type": "smaller"
    },
    {
      "id": 23,
      "name": "qt (US)",
      "category_id": [
        6,
        "Volume"
      ],
      "uom_type": "smaller"
    },
    {
      "id": 1,
      "name": "Stuks",
      "category_id": [
        1,
        "Eenheid"
      ],
      "uom_type": "reference"
    },
    {
      "id": 3,
      "name": "Dagen",
      "category_id": [
        3,
        "Werktijd"
      ],
      "uom_type": "reference"
    },
    {
      "id": 5,
      "name": "m",
      "category_id": [
        4,
        "Lengte / Afstand"
      ],
      "uom_type": "reference"
    },
    {
      "id": 9,
      "name": "m²",
      "category_id": [
        5,
        "Oppervlakte"
      ],
      "uom_type": "reference"
    },
    {
      "id": 10,
      "name": "L",
      "category_id": [
        6,
        "Volume"
      ],
      "uom_type": "reference"
    },
    {
      "id": 12,
      "name": "kg",
      "category_id": [
        2,
        "Gewicht"
      ],
      "uom_type": "reference"
    },
    {
      "id": 24,
      "name": "gal (USA)",
      "category_id": [
        6,
        "Volume"
      ],
      "uom_type": "bigger"
    },
    {
      "id": 29,
      "name": "10 stuks",
      "category_id": [
        1,
        "Eenheid"
      ],
      "uom_type": "bigger"
    },
    {
      "id": 2,
      "name": "Dozijnen",
      "category_id": [
        1,
        "Eenheid"
      ],
      "uom_type": "bigger"
    },
    {
      "id": 26,
      "name": "ft³",
      "category_id": [
        6,
        "Volume"
      ],
      "uom_type": "bigger"
    },
    {
      "id": 27,
      "name": "100 stuks",
      "category_id": [
        1,
        "Eenheid"
      ],
      "uom_type": "bigger"
    },
    {
      "id": 7,
      "name": "km",
      "category_id": [
        4,
        "Lengte / Afstand"
      ],
      "uom_type": "bigger"
    },
    {
      "id": 11,
      "name": "m³",
      "category_id": [
        6,
        "Volume"
      ],
      "uom_type": "bigger"
    },
    {
      "id": 14,
      "name": "t",
      "category_id": [
        2,
        "Gewicht"
      ],
      "uom_type": "bigger"
    },
    {
      "id": 28,
      "name": "1000 stuks",
      "category_id": [
        1,
        "Eenheid"
      ],
      "uom_type": "bigger"
    },
    {
      "id": 20,
      "name": "mi",
      "category_id": [
        4,
        "Lengte / Afstand"
      ],
      "uom_type": "bigger"
    }
  ],
  schdulePurchaseMargin: 1,
  schduleDeliverMargin: 1,
  schedule : [
    {day: 0, time: 12.5, assortment: 'Nucleaire'},
    {day: 2, time: 15, assortment: 'Medisch'},
    {day: 0, time: 12.5, assortment: 'Basis'},
    {day: 4, time: 15, assortment: 'Technisch'},
    {day: 1, time: 12.5, assortment: 'Lab'},
    {day: 3, time: 15, assortment: 'ICT'},
  ],
  tax: [
    {id:3, name: "Verkopen/omzet laag 9%", value: 9},
    {id: 4, name: "Verkopen/omzet hoog 21%", value: 21, default:true},
    {id: 1, name: "Verkopen/omzet onbelast (nul-tarief)", value: 0},
    {id:  47, name: "Verkopen/omzet onbelast (vrijgesteld)", value: 0},
    {id: 28, name: "Verkopen export binnen EU", value: 0},
    {id: 39, name: "Verkopen export buiten EU", value: 0},
    {id: 125, name: "Gemixte Verkopen/omzet - QW293401", value: 21}
],
deliveries: {
      draft: 'Concept',
    waiting: 'Wachtend op andere verwerking',
    confirmed: 'Wachten',
    assigned: 'Te ontvangen',
    done: 'Gereed',
    cancel: 'Geannuleerd'
    },
  scancodes: [
    {name: "PACK CODE", size: 8, regexp: `^PACK[0-9]{3,}$`, field: 'pack'},
    {name: "PACK CODE", size: 8, regexp: `^PACK/[0-9]{3,}$`, field: 'pack'},
    {name: "PACK CODE", size: 10, regexp: `^[0-9]{10}$`, field: 'pack'},
   
    {name: "DELIVERY CODE", size: 8, regexp: `^DS[\/][0-9]{3,10}$`, field: 'reference'},
    {name: "DELIVERY CODE", size: 15, regexp: `^CC-[0-9]{3,15}$`, field: 'reference'},
    {name: "DELIVERY CODE", size: 8, regexp: `^ZXP[\/]OUT[\/][0-9]{3,10}$`, field: 'reference'},
    {name: "DELIVERY CODE", size: 8, regexp: `^[A-Z0-9]{3,10}[\/][A-Z0-9]{3,10}[\/][0-9]{3,5}$`, field: 'reference'},
    {name: "CABINET CODE", size: 8, regexp: `^[A-Z0-9a-z_-]{2,10}[*][0-9]{3}[*][0-9]{3}$`, split : '*',
      parts: [
        {name: "Cabinetcode", field: 'cabinet', index:0 },
        {name: "Shelf", size:3, field: 'shelf', type: 'number', index:1 },
        {name: "Bin", size:3, field: 'bin', type: 'number', index:2  },
      ]
    },
    {name: "EAN-6", size: 6, regexp: `^[0-9]{6}$`, code: 6, field: 'default_code'},
    {name: "EAN-7", size: 7, regexp: `^[0-9]{7}$`, code: 7, field: 'default_code'},
    {name: "EAN-8", size: 8, regexp: `^[0-9]{8}$`, code: 8, field: 'default_code'},
    //{name: "EAN-10", size: 8, regexp: `^[0-9]{10}$`, code: 8, field: 'default_code'},
    {name: "EAN-12", size: 12, regexp: `^[0-9]{12}$`, code: 12, field: 'barcode'},
    {name: "EAN-13", size: 13, regexp: `^[0-9]{13}$`, code: 12, field: 'default_code'},
    {name: "GTIN-14", size: 14, regexp: `^[0-9]{14}$`, code: 13, field: 'barcode'},
    {name: "GS1-128", regexp: "^[a-zA-Z0-9_()]{16,60}", prefix: true,
    field: 'default_code',
    parts: [
      {prefix: '01', name: "Barcode", size:14, field: 'barcode' },
      {prefix: '10', name: "Lot/batchnummer", size: 6, field: 'batch' },
      {prefix: '11', name: "Statdatum", size: 6, field: 'start_date', type: 'date' },
      {prefix: '15', name: "houdbaarheids", size: 6, field: 'expiration_date', type: 'date' },
      {prefix: '17', name: "Expiratiedatum", size: 6, field: 'expiration_date', type: 'date' },

      {prefix: '21', name: "Lotnummer", size: 8, field: 'series' },
      {prefix: '37', name: "Aantal", size: 6, field: 'quantity', type: 'number' },
      {prefix: '240',name: "Lotnummer", size: 6, field: 'series' },
      {prefix: '3102',name: "Gewicht", size: 6, field: 'weight' },
      
    ]
    },
    {name: "GS1-128I", regexp: "^[0-9]{16}$", code: 15, field: 'barcode'},
    {name: "CONTAINER CODE", size: 10, regexp: `^38719335047[0-9]{7}$`, field: 'container'},
    {name: "PCN", regexp: `^[a-zA-Z0-9_()-]{5,20}$`, code: 6, field: 'barcode'},
  ],
  pwtest: [
    {name: "Lengte", test: /.{9,}/, score:.25, description: "9 tekens" },
    {name: "Kleine letters"  , test: /[a-z]/, score:.25, description: "1 kleine letter"},
    {name: "Hoofdletters" , test: /[A-Z]/, score:.25, description: "1 hoofdletter"},
    //{name: "Getal" , test: /\d/, score:.20, description: "1 getal"},
    {name: "Speciaal" , test: /[#$@!%&*?]/, score:.25, description: "1 speciaal teken"}
],
vta_type: [
	'cat_bekend',
   'vta_bekend',
  'vta_onbekend'
],
vta_status: [
  {id: 28, code: 0, value: 'Geen', text:'-'},
  {id: 29, code: 1, value: 'Nieuw', text:'Nieuw bij Orderadmin', approve: true},
  {id: 30, code: 2, value: 'Orderadmin', text:'In Behandeling Orderadmin', approve: true},
  {id: 31, code: 3, value: 'Offerte', text:'In Behandeling Orderadmin', approve: true},
  {id: 32, code: 4, value: 'Offerte aangevraagd', text:'In Behandeling Orderadmin', approve: true},
  {id: 33, code: 5, value: 'Expertlid', text:'Beoordeling expertlid', approve: true},
  {id: 34, code: 6, value: 'Goedgekeurd', text:'Goedgekeurd'},
  {id: 35, code: 7, value: 'Afgekeurd', text:'Afgekeurd'},
  {id: 36, code: 9, value: 'Supportdesk', text:'In behandeling Supportdesk', approve: true},
  {id: 37, code: 10, value: 'Spoed', text:'Spoed beoordeling', approve: true}
  
],
templates: [
  {id: 1, value: 'default', name:'Kastinhoud printen', type: 'pdf'},
  {value: 'zxl_portal_barcode_simple', name:'Standaard barcode', type: 'label', dpm: '12dpmm', width: '2.9', height: '1'},
  {value: 'zxl_portal_barcode_cabinet', name:'Etiketten landscape', type: 'label', dpm: '12dpmm', width: '2.9', height: '1'},
  {value: 'zxl_portal_barcode_cabinet_200', name:'Etiketten landscape(200 dpi)', type: 'label', dpm: '8dpmm', width: '2.9', height: '1'},
  {value: 'zxl_portal_barcode_cabinet_II', name:'Etiketten landscape(55x35)', type: 'label', dpm: '12dpmm', width: '2.2', height: '1.6'},
  {value: 'zxl_portal_barcode_cabinet_vert', name:'Etiketten portrait', type: 'label', dpm: '12dpmm', width: '1.5', height: '2.1'},
],
printers: [
  {name: 'HLEY',hospital: 'HagaZiekenhuis',model: 'GX430t',ip: '10.120.8.17',label: 'zxl_portal_barcode_cabinet',color: 'white'},
{name: 'HLEY-1',hospital: 'HagaZiekenhuis',model: 'GX430t',ip: '10.120.8.19',label: 'zxl_portal_barcode_cabinet',color: 'white'},
{name: 'OKLEY-01',hospital: 'HagaZiekenhuis',model: 'GX430t',ip: '10.120.2.26',label: 'zxl_portal_barcode_cabinet',color: 'white'},
{name: 'OKLEY-02',hospital: 'HagaZiekenhuis',model: 'GX430t',ip: '10.120.2.27',label: 'zxl_portal_barcode_cabinet',color: 'white'},

{name: 'LLZ-LB01',hospital: 'HagaZiekenhuis Zoetermeer',model: 'GX430t',ip: '10.26.106.23',label: 'zxl_portal_barcode_cabinet_vert',color: 'white'},
{name: 'LLZ-LB02',hospital: 'HagaZiekenhuis Zoetermeer',model: 'GX430t',ip: '10.26.106.22',label: 'zxl_portal_barcode_cabinet_vert',color: 'white'},
{name: 'LLZ-LB03',hospital: 'HagaZiekenhuis Zoetermeer',model: 'GX430t',ip: '10.26.106.24',label: 'zxl_portal_barcode_cabinet_vert',color: 'white'},

{name: 'LLZ-LB01',hospital: 'Reinier Haga Orthopedisch Centrum',model: 'GX430t',ip: '10.26.106.23',label: 'zxl_portal_barcode_cabinet_vert',color: 'white'},
{name: 'LLZ-LB02',hospital: 'Reinier Haga Orthopedisch Centrum',model: 'GX430t',ip: '10.26.106.22',label: 'zxl_portal_barcode_cabinet_vert',color: 'white'},
{name: 'LLZ-LB03',hospital: 'Reinier Haga Orthopedisch Centrum',model: 'GX430t',ip: '10.26.106.24',label: 'zxl_portal_barcode_cabinet_vert',color: 'white'},

{name: 'RDKBHR01',hospital: 'Reinier de Graaf',model: 'GK420t',ip: '10.3.10.211',label: 'zxl_portal_barcode_cabinet',color: 'white'},
{name: 'RDKBHR02',hospital: 'Reinier de Graaf',model: 'GK420t',ip: '10.3.10.212',label: 'zxl_portal_barcode_cabinet',color: 'white'},
{name: 'RDKBHR03',hospital: 'Reinier de Graaf',model: 'GK420t',ip: '10.3.10.101',label: 'zxl_portal_barcode_cabinet',color: 'white'},

{name: 'SF4011',hospital: 'Franciscus Gasthuis & Vlietland',model: 'GX430t',ip: '172.30.250.7',label: 'zxl_portal_barcode_cabinet',color: 'white'},
{name: 'SF4013',hospital: 'Franciscus Gasthuis & Vlietland',model: 'GX420t',ip: '172.30.250.8',label: 'zxl_portal_barcode_cabinet',color: 'white'},
{name: 'SFKAST1',hospital: 'Franciscus Gasthuis & Vlietland',model: 'GK420t',ip: '172.30.250.13',label: 'zxl_portal_barcode_cabinet',color: 'white'},
{name: 'SFKAST2',hospital: 'Franciscus Gasthuis & Vlietland',model: 'GK420t',ip: '172.30.250.14',label: 'zxl_portal_barcode_cabinet',color: 'white'},
{name: 'SFKAST3',hospital: 'Franciscus Gasthuis & Vlietland',model: 'ZD621',ip: '172.30.8.119',label: 'zxl_portal_barcode_cabinet',color: 'white'},

{name: 'VLZ-LB01',hospital: 'Franciscus Gasthuis & Vlietland',model: 'GX430t',ip: '172.30.250.112',label: 'zxl_portal_barcode_cabinet_vert',color: 'white'},
{name: 'VLZ-LB02',hospital: 'Franciscus Gasthuis & Vlietland',model: 'GX430t',ip: '172.30.250.114',label: 'zxl_portal_barcode_cabinet_vert',color: 'white'},

{name: 'YSL_LB01',hospital: 'IJsselland',model: 'GX430t',ip: '172.30.250.67',label: 'zxl_portal_barcode_cabinet_II',color: 'white'},
{name: 'YSL-LB02',hospital: 'IJsselland',model: 'GX430t',ip: '172.30.250.68',label: 'zxl_portal_barcode_cabinet_II',color: 'white'},

{name: 'LB01',hospital: 'Zorgservice XL',model: 'ZT230-220dpi',ip: '172.30.8.54',label: 'zxl_portal_barcode_cabinet',color: 'white'},
{name: 'LB02',hospital: 'Zorgservice XL',model: 'ZT230-220dpi',ip: '172.30.8.102',label: 'zxl_portal_barcode_cabinet',color: 'white'},
{name: 'LB03',hospital: 'Zorgservice XL',model: 'ZT230-220dpi',ip: '172.30.8.85',label: 'zxl_portal_barcode_cabinet',color: 'white'},
{name: 'LB04',hospital: 'Zorgservice XL',model: 'ZT230-220dpi',ip: '172.30.8.82',label: 'zxl_portal_barcode_cabinet',color: 'white'},
{name: 'LB05',hospital: 'Zorgservice XL',model: 'ZT230-220dpi',ip: '172.30.8.80',label: 'zxl_portal_barcode_cabinet',color: 'white'},
{name: 'LB06',hospital: 'Zorgservice XL',model: 'ZT230-220dpi',ip: '172.30.8.54',label: 'zxl_portal_barcode_cabinet',color: 'white'},
{name: 'LB07',hospital: 'Zorgservice XL',model: 'ZT230-220dpi',ip: '172.30.8.60',label: 'zxl_portal_barcode_cabinet',color: 'white'},
{name: 'LB08',hospital: 'Zorgservice XL',model: 'ZT230-220dpi',ip: '172.30.8.81',label: 'zxl_portal_barcode_cabinet',color: 'white'},
{name: 'LB09',hospital: 'Zorgservice XL',model: 'ZT230-220dpi',ip: '172.30.8.62',label: 'zxl_portal_barcode_cabinet',color: 'white'},
{name: 'LB11',hospital: 'Zorgservice XL',model: 'ZT230-220dpi',ip: '172.30.8.64',label: 'zxl_portal_barcode_cabinet',color: 'white'},
{name: 'LB12',hospital: 'Zorgservice XL',model: 'ZT230-220dpi',ip: '172.30.8.65',label: 'zxl_portal_barcode_cabinet',color: 'white'},
{name: 'LB14',hospital: 'Zorgservice XL',model: 'ZT230-220dpi',ip: '172.30.8.83',label: 'zxl_portal_barcode_cabinet',color: 'white'},
{name: 'LB15',hospital: 'Zorgservice XL',model: 'ZT230-220dpi',ip: '172.30.8.68',label: 'zxl_portal_barcode_cabinet',color: 'white'},
{name: 'LB17',hospital: 'Zorgservice XL',model: 'ZT230-220dpi',ip: '172.30.8.106',label: 'zxl_portal_barcode_cabinet',color: 'white'},
{name: 'LB18',hospital: 'Zorgservice XL',model: 'ZT230-220dpi',ip: '172.30.8.120',label: 'zxl_portal_barcode_cabinet',color: 'white'},
{name: 'LB21',hospital: 'Zorgservice XL',model: 'ZT230-220dpi',ip: '172.30.8.84',label: 'zxl_portal_barcode_cabinet',color: 'white'},
{name: 'LB22',hospital: 'Zorgservice XL',model: 'GK420t',ip: '172.30.8.115',label: 'zxl_portal_barcode_cabinet',color: 'white'},
{name: 'LB24',hospital: 'Zorgservice XL',model: 'ZT230-220dpi',ip: '172.30.8.112',label: 'zxl_portal_barcode_cabinet',color: 'white'},
{name: 'LB25',hospital: 'Zorgservice XL',model: 'ZT230-220dpi',ip: '172.30.8.126',label: 'zxl_portal_barcode_cabinet',color: 'white'},
{name: 'LB26',hospital: 'Zorgservice XL',model: 'ZT230-220dpi',ip: '172.30.8.125',label: 'zxl_portal_barcode_cabinet',color: 'white'},
{name: 'LB27',hospital: 'Zorgservice XL',model: 'ZT230-220dpi',ip: '172.30.8.117',label: 'zxl_portal_barcode_cabinet',color: 'white'},]
,safety_adr : [
  {
      "id": 14,
      "name": "1"
  },
  {
      "id": 15,
      "name": "2"
  },
  {
      "id": 16,
      "name": "3"
  },
  {
      "id": 17,
      "name": "4.1"
  },
  {
      "id": 18,
      "name": "4.2"
  },
  {
      "id": 19,
      "name": "4.3"
  },
  {
      "id": 20,
      "name": "5.1"
  },
  {
      "id": 21,
      "name": "5.2"
  },
  {
      "id": 22,
      "name": "6.1"
  },
  {
      "id": 23,
      "name": "6.2"
  },
  {
      "id": 24,
      "name": "7"
  },
  {
      "id": 25,
      "name": "8"
  },
  {
      "id": 26,
      "name": "9"
  }
],
safety_un: [
  {
      "id": 110,
      "name": "0",
      "description": "MSDS zonder UN nummer"
  },
  {
      "id": 111,
      "name": "UN0000",
      "description": "Tbv PGS opslag"
  },
  {
      "id": 112,
      "name": "UN1057",
      "description": "NAVULPATRONEN VOOR AANSTEKERS"
  },
  {
      "id": 113,
      "name": "UN1080",
      "description": "ZWAVELHEXAFLUORIDE"
  },
  {
      "id": 114,
      "name": "UN1090",
      "description": "ACETON"
  },
  {
      "id": 115,
      "name": "UN1139",
      "description": "BESCHERMLAK, OPLOSSING"
  },
  {
      "id": 116,
      "name": "UN1155",
      "description": "DIETHYLETHER (ETHYLETHER)"
  },
  {
      "id": 117,
      "name": "UN1170",
      "description": "ETHANOL, OPLOSSING"
  },
  {
      "id": 118,
      "name": "UN1173",
      "description": "ETHYLACETAAT"
  },
  {
      "id": 119,
      "name": "UN1208",
      "description": "HEXANEN"
  },
  {
      "id": 120,
      "name": "UN1210",
      "description": "DRUKINKT, BRANDBAAR"
  },
  {
      "id": 121,
      "name": "UN1219",
      "description": "ISOPROPYLALCOHOL (ISOPRPOPANOL)"
  },
  {
      "id": 122,
      "name": "UN1224",
      "description": "KETONEN, VLOEIBAAR N.E.G."
  },
  {
      "id": 123,
      "name": "UN1230",
      "description": "METHANOL"
  },
  {
      "id": 124,
      "name": "UN1247",
      "description": "METHYLMETACRYLAAT, MONOMEER, GESTABILISEERD"
  },
  {
      "id": 125,
      "name": "UN1263",
      "description": "VERF VERWANTE PRODUCTEN"
  },
  {
      "id": 126,
      "name": "UN1265",
      "description": "PENTANEN"
  },
  {
      "id": 127,
      "name": "UN1268",
      "description": "AARDOLIEDESTILLATEN N.E.G."
  },
  {
      "id": 128,
      "name": "UN1274",
      "description": "n-PROPANOL, n-PROPYLACOHOL"
  },
  {
      "id": 129,
      "name": "UN1282",
      "description": "PYRIDINE"
  },
  {
      "id": 130,
      "name": "UN1296",
      "description": "TRIETHYLAMINE"
  },
  {
      "id": 131,
      "name": "UN1307",
      "description": "XYLENEN"
  },
  {
      "id": 132,
      "name": "UN1436",
      "description": "ZINKPOEDER"
  },
  {
      "id": 133,
      "name": "UN1479",
      "description": "OXIDERENDE VASTE STOF, N.E.G (kaliumjodaat)"
  },
  {
      "id": 134,
      "name": "UN1549",
      "description": "ANORGANISCHE ANTIMOON VERBINDING NEG"
  },
  {
      "id": 135,
      "name": "UN1593",
      "description": "DICHLOORMETHAAN"
  },
  {
      "id": 136,
      "name": "UN1648",
      "description": "ACETONITRIL"
  },
  {
      "id": 137,
      "name": "UN1671",
      "description": "FENOL, VAST"
  },
  {
      "id": 138,
      "name": "UN1687",
      "description": "NATRIUMAZIDE"
  },
  {
      "id": 139,
      "name": "UN1719",
      "description": "BIJTENDE ALKALISCHE VLOEISTOF, N.E.G."
  },
  {
      "id": 140,
      "name": "UN1759",
      "description": "BIJTENDE VASTE STOF N.E.G."
  },
  {
      "id": 141,
      "name": "UN1760",
      "description": "BIJTENDE VLOEISTOF N.E.G."
  },
  {
      "id": 142,
      "name": "UN1779",
      "description": "MIERENZUUR"
  },
  {
      "id": 143,
      "name": "UN1789",
      "description": "CHLOORWATERSTOFZUUR (ZOUTZUUR)"
  },
  {
      "id": 144,
      "name": "UN1791",
      "description": "HYPOCHLORIET, OPLOSSING"
  },
  {
      "id": 145,
      "name": "UN1802",
      "description": "PERCHLOORZUUR"
  },
  {
      "id": 146,
      "name": "UN1805",
      "description": "FOSFORZUUR"
  },
  {
      "id": 147,
      "name": "UN1813",
      "description": "KALIUMHYDROXIDE, VAST"
  },
  {
      "id": 148,
      "name": "UN1814",
      "description": "KALIUMHYDROXIDE, OPLOSSING (KALILOOG)"
  },
  {
      "id": 149,
      "name": "UN1823",
      "description": "NATRIUMHYDROXIDE, VAST"
  },
  {
      "id": 150,
      "name": "UN1824",
      "description": "NATRIUMHYDROXIDE, OPLOSSING(NATRONLOOG)"
  },
  {
      "id": 151,
      "name": "UN1830",
      "description": "ZWAVELZUUR, met meer dan 51% zuur"
  },
  {
      "id": 152,
      "name": "UN1866",
      "description": "HARSOPLOSSING, brandbaar"
  },
  {
      "id": 153,
      "name": "UN1866",
      "description": "HARSOPLOSSING, brandbaar"
  },
  {
      "id": 154,
      "name": "UN1873",
      "description": "PERCHLOORZUUR"
  },
  {
      "id": 155,
      "name": "UN1888",
      "description": "CHLOROFORM"
  },
  {
      "id": 156,
      "name": "UN1903",
      "description": "DESINFECTIEMIDDEL, VLOEIBAAR, BIJTEND, N.E.G"
  },
  {
      "id": 157,
      "name": "UN1950",
      "description": "SPUITBUSSEN, verstikkend"
  },
  {
      "id": 158,
      "name": "UN1950",
      "description": "SPUITBUSSEN, oxiderend"
  },
  {
      "id": 159,
      "name": "UN1950",
      "description": "SPUITBUSSEN, giftig"
  },
  {
      "id": 160,
      "name": "UN1950",
      "description": "SPUITBUSSEN, bijtend"
  },
  {
      "id": 161,
      "name": "UN1950",
      "description": "SPUITBUSSEN, bijtend, oxiderend"
  },
  {
      "id": 162,
      "name": "UN1950",
      "description": "SPUITBUSSEN, brandbaar"
  },
  {
      "id": 163,
      "name": "UN1950",
      "description": "SPUITBUSSEN, giftig, brandbaar"
  },
  {
      "id": 164,
      "name": "UN1950",
      "description": "SPUITBUSSEN, brandbaar, bijtend"
  },
  {
      "id": 165,
      "name": "UN1950",
      "description": "SPUITBUSSEN, giftig, brandbaar, bijtend"
  },
  {
      "id": 166,
      "name": "UN1950",
      "description": "SPUITBUSSEN, giftig, oxiderend"
  },
  {
      "id": 167,
      "name": "UN1950",
      "description": "SPUITBUSSEN, giftig, bijtend"
  },
  {
      "id": 168,
      "name": "UN1950",
      "description": "SPUITBUSSEN, giftig, oxiderend, bijtend"
  },
  {
      "id": 169,
      "name": "UN1956",
      "description": "SAMENGEPERST GAS, N.E.G."
  },
  {
      "id": 170,
      "name": "UN1986",
      "description": "ALCOHOLEN, BRANDBAAR, GIFTIG, N.E.G."
  },
  {
      "id": 171,
      "name": "UN1987",
      "description": "ALCOHOLEN, BRANDBAAR, N.E.G."
  },
  {
      "id": 172,
      "name": "UN1992",
      "description": "BRANDBARE VLOEISTOF, GIFTIG, N.E.G."
  },
  {
      "id": 173,
      "name": "UN1993",
      "description": "BRANDBARE VLOEISTOF, N.E.G."
  },
  {
      "id": 174,
      "name": "UN2014",
      "description": "WATERSTOFPEROXIDE, OPLOSSING IN WATER"
  },
  {
      "id": 175,
      "name": "UN2031",
      "description": "SALPETERZUUR MINDER DAN 65%"
  },
  {
      "id": 176,
      "name": "UN2037",
      "description": "HOUDERS, KLEIN MET GAS, GASPATRONEN)"
  },
  {
      "id": 177,
      "name": "UN2056",
      "description": "TETRAHYDROFURAN"
  },
  {
      "id": 178,
      "name": "UN2059",
      "description": "NITROCELLULOSE, OPLOSSING, BRANDBAAR"
  },
  {
      "id": 179,
      "name": "UN2209",
      "description": "FORMALDEHYDE, OPLOSSING"
  },
  {
      "id": 180,
      "name": "UN2424",
      "description": "OCTAFLUORPROPAAN R218 KOELGAS"
  },
  {
      "id": 181,
      "name": "UN2465",
      "description": "DICHLOORISOCYANUURZUUR DROOG OF ZOUTEN"
  },
  {
      "id": 182,
      "name": "UN2491",
      "description": "ETHANOLAMINE, OPLOSSING"
  },
  {
      "id": 183,
      "name": "UN2564",
      "description": "TRICHLOORAZIJNZUUR, OPLOSSING"
  },
  {
      "id": 184,
      "name": "UN2670",
      "description": "CYANUURCHLORIDE"
  },
  {
      "id": 185,
      "name": "UN2672",
      "description": "AMMONIAK, OPLOSSING"
  },
  {
      "id": 186,
      "name": "UN2699",
      "description": "TRIFLUORAZIJNZUUR"
  },
  {
      "id": 187,
      "name": "UN2735",
      "description": "AMINEN VLOEIBAAR CORROSIEF"
  },
  {
      "id": 188,
      "name": "UN2789",
      "description": "IJSAZIJN of AZIJNZUUR, OPLOSSING"
  },
  {
      "id": 189,
      "name": "UN2790",
      "description": "AZIJNZUUR, OPLOSSING"
  },
  {
    "id": 190,
    "name": "UN2796",
    "description": "ACCUMULATORVLOEISTOF, ZUUR"
},
{
    "id": 191,
    "name": "UN2811",
    "description": "GIFTIGE ORGANISCHE VASTE STOF, N.E.G."
},
{
    "id": 192,
    "name": "UN2813",
    "description": "MET WATER REACTIEVE VASTE STOF, N.E.G."
},
{
    "id": 193,
    "name": "UN2821",
    "description": "FENOL, OPLOSSING"
},
{
    "id": 194,
    "name": "UN2910",
    "description": "RADIOACTIEVE STOFFEN, VRIJGESTELD COLLO"
},
{
    "id": 195,
    "name": "UN2920",
    "description": "BIJTENDE VLOEISTOF, BRANDBAAR, N.E.G."
},
{
    "id": 196,
    "name": "UN2924",
    "description": "BRANDBARE VLOEISTOF, BIJTEND, N.E.G."
},
{
    "id": 197,
    "name": "UN2967",
    "description": "SULFAMINEZUUR (aminosulfonzuur)"
},
{
    "id": 198,
    "name": "UN3077",
    "description": "MILIEUGEVAARLIJKE VASTE STOF, N.E.G."
},
{
    "id": 199,
    "name": "UN3082",
    "description": "MILIEUGEVAARLIJKE VLOEISTOF, N.E.G."
},
{
    "id": 200,
    "name": "UN3091",
    "description": "LITHIUM BATTERIJEN"
},
{
    "id": 201,
    "name": "UN3091",
    "description": "LITHIUM BATTERIJEN MET APPARATUUR"
},
{
    "id": 202,
    "name": "UN3109",
    "description": "ORGANISCH PEROXIDE, TYPE F, VLOEIBAAR"
},
{
    "id": 203,
    "name": "UN3149",
    "description": "WATERSTOFPEROXIDE EN PEROXYAZIJNZUUR, MENGSEL GESTABILISEERD"
},
{
    "id": 204,
    "name": "UN3175",
    "description": "VASTE STOFFEN DIE BRANDBARE VLOEISTOFFEN BEVATTEN N.E.G."
},
{
    "id": 205,
    "name": "UN3253",
    "description": "DINATRIUMTRIOXOSILICAAT"
},
{
    "id": 206,
    "name": "UN3260",
    "description": "BIJTENDE ZURE, ANORGANISCHE VASTE STOF N.E.G."
},
{
    "id": 207,
    "name": "UN3261",
    "description": "BIJTENDE ZURE, ORGANISCHE VASTE ST. N.E.G."
},
{
    "id": 208,
    "name": "UN3262",
    "description": "BIJTENDE BASISCHE, ANORGANISCHE VASTE STOF"
},
{
    "id": 209,
    "name": "UN3263",
    "description": "BIJTENDE BASISCHE, ORGANISCHE VASTE STOF"
},
{
    "id": 210,
    "name": "UN3264",
    "description": "BIJTENDE ZURE, ANORGANISCHE VLOEIST. N,E,G."
},
{
    "id": 211,
    "name": "UN3265",
    "description": "BIJTENDE ZURE, ORGANISCHE VLOEISTOF N.E.G."
},
{
    "id": 212,
    "name": "UN3266",
    "description": "BIJTENDE BASISSCHE, ANORGANISCHE VLOEISTOF"
},
{
    "id": 213,
    "name": "UN3267",
    "description": "BIJTENDE BASISSCHE, ORGANISCHE VLOEISTOF"
},
{
    "id": 214,
    "name": "UN3287",
    "description": "GIFTIGE ANORGANISCHE VLOEISTOF N.E.G."
},
{
    "id": 215,
    "name": "UN3295",
    "description": "KOOLWATERSTOFFEN, VLOEIBAAR N.E.G."
},
{
    "id": 216,
    "name": "UN3334",
    "description": "Vaste stof onderworpen aan de voorschriften voor de luchtvaart"
},
{
    "id": 217,
    "name": "UN3373",
    "description": "BIOLOGISCHE STOF CATEGORIE B"
},
{
    "id": 218,
    "name": "UN3453",
    "description": "FOSFORZUUR,VAST"
},
{
    "id": 219,
    "name": "CMR",
    "description": "Formaldehyde"
},
{
    "id": 220,
    "name": "UN2331",
    "description": "ZINKCHLORIDE, WATERVRIJ"
},
{
    "id": 221,
    "name": "UN2265",
    "description": "N,N-DIMETHYLFORMAMIDE"
},
{
    "id": 222,
    "name": "UN3286",
    "description": "BRANDBARE VLOEISTOF, GIFTIG, BIJTEND, N.E.G"
},
{
    "id": 223,
    "name": "UN1415",
    "description": "LITHIUM"
},
{
    "id": 224,
    "name": "UN1547",
    "description": "ANILINE"
},
{
    "id": 225,
    "name": "UN1736",
    "description": "BENZOYLCHLORIDE"
},
  
],
safety_class: [
  {
      "id": 107,
      "name": "6F voorwerpen met brandbaar gas, n.e.g."
  },
  {
      "id": 108,
      "name": "2A vloeibaar gemaakt gas, n.e.g."
  },
  {
      "id": 118,
      "name": "FT1 brandbare vloeistoffen, giftig"
  },
  {
      "id": 125,
      "name": "FC brandbare vloeistoffen. bijtend"
  },
  {
      "id": 127,
      "name": "WS stoffen die in contact met water brandbare gassen ontwikkelen, voor zelfverhitting vatbaar, vast"
  },
  {
      "id": 128,
      "name": "O2 oxiderend vaste stoffen zonder bijkomend gevaar"
  },
  {
      "id": 131,
      "name": "F1 brandbare vloeistof; vlampunt ten hoogste 60 °C"
  },
  {
      "id": 132,
      "name": "T2 giftige organische vaste stof"
  },
  {
      "id": 133,
      "name": "T5 giftige anorganische vaste stof"
  },
  {
      "id": 134,
      "name": "C5 basische stof, anorganisch, vloeibaar"
  },
  {
      "id": 135,
      "name": "C10 andere bijtende stof, vast"
  },
  {
      "id": 140,
      "name": "CO1 bijtende stof, oxiderend, vloeibaar"
  },
  {
      "id": 141,
      "name": "C1 zure stof, anorganisch vloeibaar"
  },
  {
      "id": 142,
      "name": "C6 basische stof, anorganisch, vast"
  },
  {
      "id": 149,
      "name": "OC1 oxiderende vloeistoffen, bijtend"
  },
  {
      "id": 152,
      "name": "5A verstikkend"
  },
  {
      "id": 153,
      "name": "5O oxiderend"
  },
  {
      "id": 154,
      "name": "5T giftig"
  },
  {
      "id": 157,
      "name": "5F brandbaar"
  },
  {
      "id": 158,
      "name": "5TF giftig, brandbaar"
  },
  {
      "id": 160,
      "name": "5TFC giftig, brandbaar, bijtend"
  },
  {
      "id": 161,
      "name": "5TO giftig, oxiderend"
  },
  {
      "id": 162,
      "name": "5TC giftig, bijtend"
  },
  {
      "id": 163,
      "name": "5TOC giftig, oxiderend, bijtend"
  },
  {
      "id": 164,
      "name": "1A samengeperst gas, n.e.g."
  },
  {
      "id": 173,
      "name": "D vaste ontplofbare stof in niet explosieve toestand zonder bijkomend gevaar"
  },
  {
      "id": 174,
      "name": "C9 andere bijtende stof, vloeibaar"
  },
  {
      "id": 175,
      "name": "2A koelgas n.e.g."
  },
  {
      "id": 179,
      "name": "C4 zure stof, organisch, vast"
  },
  {
      "id": 181,
      "name": "C3 zure stof, organisch, vloeibaar"
  },
  {
      "id": 183,
      "name": "CF1 bijtende stof, brandbaar, vloeibaar"
  },
  {
      "id": 187,
      "name": "W2 vaste stoffen die in contact met water brandbare gassen ontwikkelen, zonder bijkomend gevaar"
  },
  {
      "id": 188,
      "name": "T1 giftige organische vloeistof"
  },
  {
      "id": 190,
      "name": "FC brandbare vloeistof, bijtend"
  },
  {
      "id": 192,
      "name": "M7 milieugevaarlijke stof die het aquatisch milieu verontreinigd, vast"
  },
  {
      "id": 193,
      "name": "M6 milieugevaarlijke stof die het aquatisch milieu verontreinigd, vloeibaar"
  },
  {
      "id": 195,
      "name": "M4 lithiumbatterij"
  },
  {
      "id": 196,
      "name": "P1 organische peroxide"
  },
  {
      "id": 200,
      "name": "C2 zure stof, anorganisch, vast"
  },
  {
      "id": 203,
      "name": "C8 basische stof, organisch, vast"
  },
  {
      "id": 207,
      "name": "C7 basische stof, organisch, vloeibaar"
  },
  {
      "id": 208,
      "name": "T4 giftige anorganische vloeistof"
  },
  {
      "id": 210,
      "name": "M11 andere stof of voorwerp, die tijdens het vervoer een gevaar vertonen maar die niet onder een andere klasse vallen"
  },
  {
      "id": 211,
      "name": "I4 biologische stoffen"
  },
  {
      "id": 213,
      "name": "FTC brandbare vloeistoffen, giftig, bijtend"
  },
  {
      "id": 214,
      "name": "5C bijtend"
  },
  {
      "id": 215,
      "name": "5FC brandbaar, bijtend"
  },
  {
      "id": 216,
      "name": "F brandbare vaste stoffen zonder bijkomend gevaar"
  },
  {
      "id": 217,
      "name": "F1 brandbare vaste stoffen, organisch"
  },
  {
      "id": 218,
      "name": "F2 brandbare vaste stoffen, organisch, gesmolten"
  },
  {
      "id": 219,
      "name": "F3 brandbare vaste stoffen, anorganisch"
  },
  {
      "id": 220,
      "name": "F4 brandbare vaste stoffen, voorwerpen"
  },
  {
      "id": 221,
      "name": "CF1 Bijtende stof, vloeibaar, brandbaar"
  },
  {
      "id": 222,
      "name": "D vloeibare ontplofbare stoffen in niet explosieve toestand"
  }
]
,ribbons: [
  'Uitfaseren project;primary',
  'Tijdelijk niet leverbaar;danger',
  'Uit assortiment;danger',
  'Infaseren;primary',
  'onbestelbaar;danger',
  'Koop allocatie;warning',
  'Uitfaseren;primary',
  'alleen kast;primary',
  ]
}


export default config
/*
SFKAST3 (VS-PS-01)	Franciscus Gasthuis & Vlietland
SFKAST1 (VS-PS-01)	Franciscus Gasthuis & Vlietland
RDKBHR03 (VS-PS-01)	Reinier de Graaf
SF4013 (VS-PS-01)	Franciscus Gasthuis & Vlietland
LLZ-LB02 (VS-PS-01)	HagaZiekenhuis Zoetermeer
HLEY-1 (VS-PS-01)	HagaZiekenhuis
LLZ-LB01 (VS-PS-01)	Haga Zoetermeer
YSL-LB02 (VS-PS-01)	IJsselland Ziekenhuis
VLZ-LB02 (VS-PS-01)	Franciscus Gasthuis & Vlietland
LLZ-LB03 (VS-PS-01)	HagaZiekenhuis Zoetermeer
SF4011 (VS-PS-01)	Franciscus Gasthuis & Vlietland
HSPORT-1 (VS-PS-01)	HagaZiekenhuis
YSL_LB01 (VS-PS-01)	IJsselland Ziekenhuis
VLZ-LB01 (VS-PS-01)	Franciscus Gasthuis & Vlietland
HSPORT (VS-PS-01)	HagaZiekenhuis
RDKBHR02 (VS-PS-01)	Reinier de Graaf
OKLEY-01 (VS-PS-01)	HagaZiekenhuis
OKLEY-02 (VS-PS-01)	HagaZiekenhuis
SFKAST2 (VS-PS-01)	Franciscus Gasthuis & Vlietland
RDKBHR01 (VS-PS-01) 	Reinier de Graaf*/
